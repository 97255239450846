import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import Grid from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';

const Keepsakes = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, Keepsakes personal project"
      themeClass="theme--keepsakes"
    />

    <ProjectHeader
      name="Keepsakes"
      subhead="An automatic journal"
      sectionid="keepsakes"
    />

    <Text>
      <p>
        Keepsakes is a desktop web app that generates an automatic journal based
        on data from other apps. Moves, Instagram, Fitbit, etc. can be combined
        to form a pretty clear picture of a day: where you were, how you slept,
        what the weather was like, what you saw, talked and thought about, even
        the song that was stuck in your head. This project is the oldest on the
        site, from 2013–14, and was part of my BFA capstone — I did all design
        and development.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        youTubeVideo="E6k0-NsmexI"
        width="1600"
        height="1000"
      >
        Remembering a few days in Europe, with Keepsakes.
      </AssetWithCaption>
    </Grid>

    <Text>
      <p>
        At the time I was creating a lot of responsive sites for thoughtbot. I
        often felt that using the same markup and compatible layouts across
        platforms was restricting the desktop experience. I wanted to make
        something that leveraged desktop, relied on mouse and hover
        interactions, and used CSS’ 3D features extensively.
      </p>
      <p>
        The main element is a map, so it makes sense for the rest of the app to
        be spatial, rather than a 2D document with buttons and a flat map.
        Keepsakes is a 3D space with “zones” that exist above, below, beside,
        and over the map.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        image="keepsakes/panel_date_list_1"
        width="2880"
        height="1800"
      >
        Above the map, a calendar.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        image="keepsakes/overlay_places_list"
        width="2880"
        height="1800"
      >
        Over the map, a list of destinations.
      </AssetWithCaption>

      <AssetWithCaption
        position="left_half"
        image="keepsakes/panel_bottom_peek"
        width="2880"
        height="1800"
      >
        An information summary shown on hover.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        image="keepsakes/overlay_place_tiles"
        width="2880"
        height="1800"
      >
        A destination detail view, showing the songs I listened to at the book
        store.
      </AssetWithCaption>
    </Grid>

    <Text>
      <p>
        Hover interactions allow users to explore these zones and peek at
        information — at first this acts as an onboarding hint, but it remains
        useful to get information at a glance.
      </p>
      <p>
        Using custom cursors and animation cues with these hover interactions,
        to indicate the click action, allows much less traditional UI to be
        used. Fewer buttons means more space for the map.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        video="keepsakes/cursors_gl_wide_fast"
        width="1600"
        height="800"
      >
        Cursors instead of buttons.
      </AssetWithCaption>
    </Grid>

    <Text noBottomMargin>
      <p>
        When I made Keepsakes I had only been gathering data for about a year. I
        continued to use services like Moves until they shut down, and since
        then I’ve decreased the amount of tracking I do. Almost none of the APIs
        I used for this project still exist, so over time it has demonstrated to
        me the value of owning your own data, rather than relying on third
        parties.
      </p>
    </Text>
  </Layout>
);

export default Keepsakes;
